var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tárgyév"}},[_c('validation-provider',{attrs:{"name":"tárgyév","rules":"required","vid":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"2022","max":"9999","placeholder":"2022"},model:{value:(_vm.formData.year),callback:function ($$v) {_vm.$set(_vm.formData, "year", $$v)},expression:"formData.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"horgászhelyek másolása","vid":"copyFishingSpots"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},on:{"input":_vm.resetEventCopy},model:{value:(_vm.formData.copyFishingSpots),callback:function ($$v) {_vm.$set(_vm.formData, "copyFishingSpots", $$v)},expression:"formData.copyFishingSpots"}},[_vm._v(" Horgászhelyek másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"események másolása","vid":"copyEvents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":!_vm.canCopyEvents,"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.copyEvents),callback:function ($$v) {_vm.$set(_vm.formData, "copyEvents", $$v)},expression:"formData.copyEvents"}},[_vm._v(" Események másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"időnaptár másolása","vid":"copyDayIntervals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.copyDayIntervals),callback:function ($$v) {_vm.$set(_vm.formData, "copyDayIntervals", $$v)},expression:"formData.copyDayIntervals"}},[_vm._v(" Időnaptár másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"rendkívüli napok másolása","vid":"copySpecialDays"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.copySpecialDays),callback:function ($$v) {_vm.$set(_vm.formData, "copySpecialDays", $$v)},expression:"formData.copySpecialDays"}},[_vm._v(" Rendkívüli napok másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"árazás másolása","vid":"copyPricing"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.copyPricing),callback:function ($$v) {_vm.$set(_vm.formData, "copyPricing", $$v)},expression:"formData.copyPricing"}},[_vm._v(" Árazás másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"legfrissebb térkép másolása","vid":"copyMap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.copyMap),callback:function ($$v) {_vm.$set(_vm.formData, "copyMap", $$v)},expression:"formData.copyMap"}},[_vm._v(" Legfrissebb térkép másolása ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Tárgyév nyitása ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }