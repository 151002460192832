<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>

          <b-col md="12">
            <b-form-group
                label="Tárgyév"
            >
              <validation-provider
                  #default="{ errors }"
                  name="tárgyév"
                  rules="required"
                  vid="year"
              >
                <b-form-input
                    v-model="formData.year"
                    type="number"
                    min="2022"
                    max="9999"
                    placeholder="2022"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="horgászhelyek másolása"
                vid="copyFishingSpots"
            >
              <b-form-checkbox
                  v-model="formData.copyFishingSpots"
                  :state="errors.length > 0 ? false:null"
                  @input="resetEventCopy"
              >
                Horgászhelyek másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="események másolása"
                vid="copyEvents"
            >
              <b-form-checkbox
                  v-model="formData.copyEvents"
                  :disabled="!canCopyEvents"
                  :state="errors.length > 0 ? false:null"
              >
                Események másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="időnaptár másolása"
                vid="copyDayIntervals"
            >
              <b-form-checkbox
                  v-model="formData.copyDayIntervals"
                  :state="errors.length > 0 ? false:null"
              >
                Időnaptár másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="rendkívüli napok másolása"
                vid="copySpecialDays"
            >
              <b-form-checkbox
                  v-model="formData.copySpecialDays"
                  :state="errors.length > 0 ? false:null"
              >
                Rendkívüli napok másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="árazás másolása"
                vid="copyPricing"
            >
              <b-form-checkbox
                  v-model="formData.copyPricing"
                  :state="errors.length > 0 ? false:null"
              >
                Árazás másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="legfrissebb térkép másolása"
                vid="copyMap"
            >
              <b-form-checkbox
                  v-model="formData.copyMap"
                  :state="errors.length > 0 ? false:null"
              >
                Legfrissebb térkép másolása
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Tárgyév nyitása
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import labels from '@/libs/datepicker/labels'
import {formatDateAllowedSymbols} from "@core/utils/filter";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        year: '',
        copyFishingSpots: true,
        copyEvents: true,
        copyDayIntervals: true,
        copyMap: true,
        copyPricing: true,
        copySpecialDays: true,
      },
      canCopyEvents: true,
      labels,
      formatDateAllowedSymbols,
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchReservationDashboard').then(response => {
      if (Boolean(response.data.closed) === false) {
        this.$helpers.showErrorToast('Tárgyév nyitására csak akkor nyílik lehetőség, ha a foglalás zárva van.');
        this.$router.push({name: 'reservation-reference-years'})
      }
    })
  },
  methods: {
    resetEventCopy() {
      this.canCopyEvents = false
      if (this.formData.copyFishingSpots === false && this.formData.copyEvents === true) {
        this.formData.copyEvents = false
        this.$helpers.showWarningToast('Horgászhelyek másolása nélkül nem másolhatod az eseményeket!')
      } else {
        this.canCopyEvents = true
      }
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          this.resetEventCopy();
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('storeReferenceYear', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-reference-years'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
